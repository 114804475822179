$env: production;
@import "@/styles/_component-base.styles.scss";

.container {
    margin: auto;
}

.supportValueProp {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #000000;
    }
}

.image {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: spacer(2xs);
    [data-style-svgtype] {
        width: 94px;
        height: 80px;
        color: color(action, base);
    }
}

.text {
    font-size: set-fontSize(18);
    margin-bottom: 0;

    @include breakpoint(md) {
        font-size: set-fontSize(20);
    }

    @include breakpoint(lg) {
        font-size: set-fontSize(24);
    }
}

.col {
    margin-bottom: spacer(3xl);

    &:last-child {
        margin-bottom: 0;
    }

    @include breakpoint(md) {
        margin-bottom: 0;
    }
}
